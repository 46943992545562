
import { defineAsyncComponent } from "vue";
import { Options, Vue } from "vue-class-component";
const UniversalPrintNotFound = defineAsyncComponent({
  loader: () => import("@/app/ui/views/universal-print/not-found.vue")
});
import Loading from "@/app/ui/components/loading/index.vue";
import { UniversalPrintController } from "@/app/ui/controllers/UniversalPrintController";

@Options({
  components: {
    Loading,
    UniversalPrintNotFound
  }
})
export default class IndexV2 extends Vue {
  mounted() {
    this.getDetail();
  }

  get isLoading() {
    return UniversalPrintController.isLoading;
  }
  get isNotFound() {
    return UniversalPrintController.isNotFound;
  }

  async getDetail() {
    // url/print/stt?q=123123123123&client=1234
    const queryParams = new URLSearchParams(window.location.search);
    const sttNo = queryParams.get("q");
    const clientId = queryParams.get("client");
    if (sttNo && clientId) {
      const resp = await UniversalPrintController.getDetailSttExternal({
        sttNo: sttNo,
        clientId: clientId
      });
      if (resp) {
        UniversalPrintController.setLoading(true);
        await this.onPrint();
        UniversalPrintController.setLoading(false);
      }
      return;
    }
    UniversalPrintController.setNotFound(true);
  }
  get detailData() {
    return UniversalPrintController.sttDetail;
  }

  async onPrint() {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    await book.default.methods.generateReceipt(
      this.detailData,
      "universal",
      this.detailData.sttShipmentId,
      1,
      false
    );
  }
}
