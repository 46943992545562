import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row font-montserrat overflow-hidden max-h-screen"
}
const _hoisted_2 = {
  id: "key-content",
  class: "false bg-gray-lp-700 min-h-screen max-h-screen flex flex-col justify-between relative text-12px xxl:text-14px",
  style: {"box-shadow":"rgb(224, 224, 224) 0px 0px 15px 0px"}
}
const _hoisted_3 = {
  id: "key-logo",
  class: "flex flex-row items-center border-b border-gray-lp-400 relative px-4 py-4"
}
const _hoisted_4 = { class: "flex flex-row items-center" }
const _hoisted_5 = { class: `px-8 flex flex-col w-full overflow-hidden relative` }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_UniversalPrintNotFound = _resolveComponent("UniversalPrintNotFound")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Loading, {
      message: "Permintaan cetak STT sedang diproses",
      modelValue: _ctx.isLoading,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isLoading = $event))
    }, null, 8, ["modelValue"]),
    (!_ctx.isLoading && _ctx.isNotFound)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode("img", {
                  src: require('@/app/ui/assets/svg/logo-client.svg'),
                  class: "mr-2 w-40",
                  alt: "logo"
                }, null, 8, ["src"])
              ])
            ])
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_UniversalPrintNotFound)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}