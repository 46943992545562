import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { UniversalPrintPresenter } from "@/app/ui/presenters/UniversalPrintPresenter";
import { PrintDataEntitas } from "@/domain/entities/Booking";

interface UniversalPrintState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  isNotFound: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "universal_print"
})
class UniversalPrintStore extends VuexModule implements UniversalPrintState {
  isLoading = false;
  isError = false;
  isNotFound = false;
  errorCause = "";
  sttDetail = new PrintDataEntitas();

  @Action
  public getDetailSttExternal(params: { sttNo: string; clientId: string }) {
    this.setLoading(true);
    this.setError(false);
    this.setNotFound(false);
    this.setErrorCause("");
    const presenter = container.resolve(UniversalPrintPresenter);
    return presenter
      .getDetailSttExternal(params.sttNo, params.clientId)
      .then((res: PrintDataEntitas) => {
        this.setDetail(res);
        return true;
      })
      .catch(() => {
        this.setError(true);
        this.setNotFound(true);
        return false;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }
  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }
  @Mutation
  setNotFound(val: boolean) {
    this.isNotFound = val;
  }
  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }
  @Mutation
  setDetail(val: any) {
    this.sttDetail = val;
  }
}

export const UniversalPrintController = getModule(UniversalPrintStore);
